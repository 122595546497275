import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TogglepasswordService {

  constructor() { }
  toggleable = true;
  toggle(event){
    if(this.toggleable){
      $(event.target).removeClass('fa-eye');
      $(event.target).addClass('fa-eye-slash');
      $(event.target.parentElement.nextSibling).attr("type","text");
      this.toggleable = false;
    } else {
      $(event.target).removeClass('fa-eye-slash');
      $(event.target).addClass('fa-eye');
      $(event.target.parentElement.nextSibling).attr("type","password");
      this.toggleable = true;
    }
  }
}
